import { useState } from "react";

function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "How can Freight Insurance Data help our agency better sell and organize leads? ",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
        {
            question: "What is the onboarding process like with Freight Insurance Data? ",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
        {
            question: "Is the software only geared to agents selling transportation insurance?  ",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
        {
            question: "Does Freight Insurance Data have a training process associated with the software? ",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
    
        // Add more FAQs as needed
    ];

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <div className="faq_section">
                <div className="faqheading">
                    <span className='aboutus'>
                        FAQ
                    </span>
                    <div className="headingoffaq">
                        Frequently asked questions
                    </div>
                    <div className="poffaq">
                        Can’t find the answer here? Check out our help center.
                    </div>
                </div>
                <div className="reviewcontainer" style={{ marginTop: "71px" }}>
                    <div className="accordioncus">
                        {faqs.map((faq, index) => (
                            <div className="accordion-item rounded-4" key={index}>
                                <h2 className="accordion-header" id={`heading${index}`}>
                                    <button
                                        className="accordion-button "
                                        type="button"
                                        onClick={() => handleAccordionClick(index)}
                                        aria-expanded={activeIndex === index}
                                        aria-controls={`collapse${index}`}
                                    >
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="arroww" >
                                                {faq.question}
                                            </div>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M16.25 7.5L10 13.75L3.75 7.5" stroke="#374147" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>

                                    </button>
                                </h2>
                                <div
                                    id={`collapse${index}`}
                                    className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                                    aria-labelledby={`heading${index}`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                       {faq.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq;
