import React from 'react'
import "./blogy.css"
import brand from "./Images/Brandlogo.svg"
import firstblog from "./Images/Firstblog.png"
import Blogright from "./Images/Everysingle.png"
import blog2 from "./Images/Blog2.png"
import Blogfooter from "./Blogfooter"
import blog3 from "./Images/blog3.png"
import BlogCards from './BlogCards'
import BlueBlogs from './BlueBlogs'
import Bloglist from './Bloglist'
import { Link } from 'react-router-dom'
function Allblogs() {
  return (
    <>


      <div className='Allblogs' style={{overflowX:"hidden"}}>
        <div className='mainblogcon'>
          <div className='topnav'>
          <Link to="/">  <img src={brand} alt='frieght logo' width="156px"></img></Link>
            <div className='navicons'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" stroke="white" stroke-width="2" stroke-miterlimit="10" />
                <path d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z" fill="white" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21 11H19C18.6056 10.9984 18.2147 11.0748 17.85 11.225C17.4853 11.3752 17.1539 11.5961 16.875 11.875C16.5961 12.1539 16.3752 12.4853 16.225 12.85C16.0748 13.2147 15.9984 13.6056 16 14V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 18H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M20 16L14 12V20L20 16Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3 15.9999C3 19.7249 3.3875 21.8999 3.675 23.0249C3.75427 23.3301 3.90418 23.6125 4.11262 23.8491C4.32106 24.0858 4.58221 24.2702 4.875 24.3874C9.0625 25.9874 16 25.9499 16 25.9499C16 25.9499 22.9375 25.9874 27.125 24.3874C27.4178 24.2702 27.6789 24.0858 27.8874 23.8491C28.0958 23.6125 28.2457 23.3301 28.325 23.0249C28.6125 21.8999 29 19.7249 29 15.9999C29 12.2749 28.6125 10.0999 28.325 8.97488C28.2457 8.66963 28.0958 8.38729 27.8874 8.15062C27.6789 7.91395 27.4178 7.72958 27.125 7.61238C22.9375 6.01238 16 6.04988 16 6.04988C16 6.04988 9.0625 6.01238 4.875 7.61238C4.58221 7.72958 4.32106 7.91395 4.11262 8.15062C3.90418 8.38729 3.75427 8.66963 3.675 8.97488C3.3875 10.0999 3 12.2749 3 15.9999Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M6 18C7.0515 17.9967 8.09327 18.2014 9.06537 18.6023C10.0375 19.0031 10.9207 19.5923 11.6642 20.3358C12.4077 21.0794 12.9969 21.9626 13.3978 22.9347C13.7986 23.9068 14.0033 24.9485 14 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 12C9.71303 12 13.274 13.475 15.8995 16.1005C18.525 18.726 20 22.287 20 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 6.00004C8.62689 5.9951 11.2287 6.51063 13.6553 7.51686C16.0818 8.52309 18.285 10.0001 20.1375 11.8625C22 13.7151 23.4769 15.9182 24.4832 18.3448C25.4894 20.7713 26.0049 23.3731 26 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.5 27C7.32843 27 8 26.3284 8 25.5C8 24.6716 7.32843 24 6.5 24C5.67157 24 5 24.6716 5 25.5C5 26.3284 5.67157 27 6.5 27Z" fill="white" />
              </svg>
            </div>
          </div>
          <div className='titleblogs'>
            <div className='blog-left'>
              <img src={firstblog} alt='firstblog'></img>
              <div className='small_blue_date'>05 Mai  2024</div>
              <div className='blog_Title'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
              </div>
              <p className='blog_para'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className='blog_right'>
              <div className='single_blog'>
                <img src={Blogright} alt='evey'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
                <span className='single_explain_title'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
                </span>
              </div>
              <div className='single_blog margt'>
                <img src={blog2} alt='evey'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
                <span className='single_explain_title'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
                </span>
              </div>
              <div className='single_blog margt'>
                <img src={blog3} alt='evey'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
                <span className='single_explain_title'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div style={{overflowX:"hidden"}}>
      <BlogCards />
      <BlueBlogs />
      <BlogCards />
      <Bloglist />
      <Blogfooter />
      </div>
     
    </>
  )
}

export default Allblogs