import React, { useEffect, useState } from 'react'
import Navbar from '../../component/Navbar'
import './style.css'
import '../../component/pricing.css'
import img from "../../images/Frame.png"
import Carousel from '../../component/Carousel';
import Emphasis from '../../component/Emphasis';
import Pricing from '../../component/Pricing';
import './regis.css'
import FormComponent from './FormComponent'
import PaymentFormComponent from './PaymentFormComponent '
function Getstarted() {
  const [activeButton, setActiveButton] = useState('Monthly');
  const [register, setRegister] = useState(true);
  const [selection, setSelection] = useState(false)
  const [payment, setPayment] = useState(false)
  const handleClick = (buttonType) => {
    setActiveButton(buttonType);
  };
  const handleform = () => {
    setRegister(false);
    setSelection(true)
  };
  const handelselection = () => {

    setSelection(false)
    setPayment(true)
  };
const handelreverse = ()=>{
  setSelection(true)
  setPayment(false)
}
const handelreverseregist = ()=>{
  setRegister(true)
  setPayment(false)
}
const handelreverseregist2 = ()=>{
  setRegister(true)
  setSelection(false)
}
  return (
    <>
      <div className='forpaymentpage'>


        <div className='Herosection herohieght'>
          <Navbar />
          <div className="slider-container">
            <div className='coveroftranspotation'>
              <span className='aboutus' style={{ paddingTop: "80px" }}>
                Registration
              </span>
              <div className='texthead textmr' style={{ color: "#fff" }}>
                Get Started Now       </div>
              <p className='paraoftranspo' style={{ color: "#fff" }}>
                Pricing built for businesses of all sizes. Always know what you’ll pay.      </p>
            </div>

            {
              payment ? (
                <>
                  <div className='deskversion'>


                  <div className="navregis">
                    <div className='sectionreg'>
                      <div className='regactive' onClick={handelreverseregist}> 1. Registration</div>
                     <div className='indicatereg indiactive'></div>
                    </div>
                 

                    <div className='sectionreg'>
                     <div className='regactive' onClick={handelreverse}> 2. Access Level</div>
                     <div className='indicatereg indiactive'></div>

                    </div>
                 
                    <div className='sectionreg'>
                    <div className='regactive'> 3. Payment</div> 
                    <div className='indicatereg indiactive'></div>

                    </div>

                  </div>
                  </div>
                  <div className='mobileversion'>
                  <div className="navregis">
                    <div className='sectionreg'>
                      <div className='regactive' onClick={handelreverseregist}> 1. Registration</div>
                     <div className='indicatereg indiactive'></div>
                    </div>
                 

                    <div className='sectionreg'>
                     <div className='regactive' onClick={handelreverse}> 2. Access lvl</div>
                     <div className='indicatereg indiactive'></div>

                    </div>
                 
                    <div className='sectionreg'>
                    <div className='regactive'> 3. Payment</div> 
                    <div className='indicatereg indiactive'></div>

                    </div>

                  </div></div>

                  <div className='form2ad'>
                    <div className='form-wraper2'>

                      <PaymentFormComponent activeButton={activeButton} setPayment={setPayment} setSelection={setSelection} />

                    </div></div>
                </>) : (null)
            }


            {
              register ? (<>
                <div className='deskversion'>


                  <div className="navregis">
                    <div className='sectionreg'>
                      <div className='regactive'> 1. Registration</div>
                     <div className='indicatereg indiactive'></div>
                    </div>
                 

                    <div className='sectionreg'>
                     <div> 2. Access Level</div>
                     <div className='indicatereg'></div>

                    </div>
                 
                    <div className='sectionreg'>
                    <div> 3. Payment</div> 
                    <div className='indicatereg'></div>

                    </div>

                  </div>  </div>
                <div className='mobileversion'>
                <div className="navregis">
                    <div className='sectionreg'>
                      <div className='regactive'> 1. Registration</div>
                     <div className='indicatereg indiactive'></div>
                    </div>
                 

                    <div className='sectionreg'>
                     <div> 2. Access lvl</div>
                     <div className='indicatereg'></div>

                    </div>
                 
                    <div className='sectionreg'>
                    <div> 3. Payment</div> 
                    <div className='indicatereg'></div>

                    </div>

                  </div> </div>
                <FormComponent handleform={handleform} />
              </>) : (null)
            }


            {
              selection ? (<>
                <div className='deskversion'>


                <div className="navregis">
                    <div className='sectionreg'>
                      <div className='regactive' onClick={handelreverseregist2}> 1. Registration</div>
                     <div className='indicatereg indiactive'></div>
                    </div>
                 

                    <div className='sectionreg'>
                     <div className='regactive'>  2. Access Level</div>
                     <div className='indicatereg indiactive'></div>

                    </div>
                 
                    <div className='sectionreg'>
                    <div> 3. Payment</div> 
                    <div className='indicatereg'></div>

                    </div>

                  </div> </div>
                <div className='mobileversion'>
                <div className="navregis">
                    <div className='sectionreg'>
                      <div className='regactive' onClick={handelreverseregist2}> 1. Registration</div>
                     <div className='indicatereg indiactive'></div>
                    </div>
                 

                    <div className='sectionreg'>
                     <div className='regactive'> 2. Access lvl</div>
                     <div className='indicatereg indiactive'></div>

                    </div>
                 
                    <div className='sectionreg'>
                    <div> 3. Payment</div> 
                    <div className='indicatereg'></div>

                    </div>

                  </div></div>
                <div className='mt-4'></div>
                <div className="yerlypadtop">
                  <div className="yearlybuttons">
                    <div
                      className={`monthbtn ${activeButton === 'Monthly' ? 'activebtn' : ''}`}
                      onClick={() => handleClick('Monthly')}
                    >
                      Monthly
                    </div>
                    <div
                      className={`monthbtn ${activeButton === 'Yearly' ? 'activebtn' : ''}`}
                      onClick={() => handleClick('Yearly')}
                    >
                      Yearly
                      <div className="savesection">{activeButton === 'Yearly'}25% save</div>
                    </div>
                  </div>
                </div>
                <Carousel handelselection={handelselection} activeButton={activeButton} />
                <Emphasis />
                <div className="transparent deskversion">
                  <div className="title_transparent">
                    <span className="head_transparent">Flexible and transparent pricing</span>
                    <div className="paraof_transparent">Pricing built for businesses of all sizes. Always know what you’ll pay.</div>
                  </div>
                  <table className="table_of_features">
                    <tr>
                      <th>Features</th>
                      <th>Basic</th>
                      <th>Medium</th>
                      <th>Advanced</th>
                    </tr>
                    <tr>
                      <td>Amount of Users</td>
                      <td>1</td>
                      <td>3</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Deep Dive</td>
                      <td>20 Reports</td>
                      <td>50 Reports</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Chameleon Carrier</td>
                      <td>30 Searches</td>
                      <td>60 Searches</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Leads Database</td>
                      <td>1 State</td>
                      <td>3 State</td>
                      <td>Nationwide</td>
                    </tr>
                    <tr>
                      <td>Prospect Tracker</td>
                      <td>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
  <path d="M22.75 10.125H5.25C4.76675 10.125 4.375 10.5168 4.375 11V23.25C4.375 23.7332 4.76675 24.125 5.25 24.125H22.75C23.2332 24.125 23.625 23.7332 23.625 23.25V11C23.625 10.5168 23.2332 10.125 22.75 10.125Z" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.0625 10.125V6.1875C10.0625 5.14321 10.4773 4.14169 11.2158 3.40327C11.9542 2.66484 12.9557 2.25 14 2.25C15.0443 2.25 16.0458 2.66484 16.7842 3.40327C17.5227 4.14169 17.9375 5.14321 17.9375 6.1875V10.125" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 18.4375C14.7249 18.4375 15.3125 17.8499 15.3125 17.125C15.3125 16.4001 14.7249 15.8125 14 15.8125C13.2751 15.8125 12.6875 16.4001 12.6875 17.125C12.6875 17.8499 13.2751 18.4375 14 18.4375Z" fill="#F54949"/>
</svg>                        </td>
                      <td><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></td>
                      <td><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></td>
                    </tr>
                    <tr>
                      <td>Insured Certificate of Insurance Portal</td>
                      <td>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
  <path d="M22.75 10.125H5.25C4.76675 10.125 4.375 10.5168 4.375 11V23.25C4.375 23.7332 4.76675 24.125 5.25 24.125H22.75C23.2332 24.125 23.625 23.7332 23.625 23.25V11C23.625 10.5168 23.2332 10.125 22.75 10.125Z" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.0625 10.125V6.1875C10.0625 5.14321 10.4773 4.14169 11.2158 3.40327C11.9542 2.66484 12.9557 2.25 14 2.25C15.0443 2.25 16.0458 2.66484 16.7842 3.40327C17.5227 4.14169 17.9375 5.14321 17.9375 6.1875V10.125" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 18.4375C14.7249 18.4375 15.3125 17.8499 15.3125 17.125C15.3125 16.4001 14.7249 15.8125 14 15.8125C13.2751 15.8125 12.6875 16.4001 12.6875 17.125C12.6875 17.8499 13.2751 18.4375 14 18.4375Z" fill="#F54949"/>
</svg>                        </td>
                      <td><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></td>
                      <td><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></td>
                    </tr>
                    <tr>
                      <td>Insurance Carrier Database</td>
                      <td>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
  <path d="M22.75 10.125H5.25C4.76675 10.125 4.375 10.5168 4.375 11V23.25C4.375 23.7332 4.76675 24.125 5.25 24.125H22.75C23.2332 24.125 23.625 23.7332 23.625 23.25V11C23.625 10.5168 23.2332 10.125 22.75 10.125Z" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.0625 10.125V6.1875C10.0625 5.14321 10.4773 4.14169 11.2158 3.40327C11.9542 2.66484 12.9557 2.25 14 2.25C15.0443 2.25 16.0458 2.66484 16.7842 3.40327C17.5227 4.14169 17.9375 5.14321 17.9375 6.1875V10.125" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 18.4375C14.7249 18.4375 15.3125 17.8499 15.3125 17.125C15.3125 16.4001 14.7249 15.8125 14 15.8125C13.2751 15.8125 12.6875 16.4001 12.6875 17.125C12.6875 17.8499 13.2751 18.4375 14 18.4375Z" fill="#F54949"/>
</svg>                        </td>
                      <td>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
  <path d="M22.75 10.125H5.25C4.76675 10.125 4.375 10.5168 4.375 11V23.25C4.375 23.7332 4.76675 24.125 5.25 24.125H22.75C23.2332 24.125 23.625 23.7332 23.625 23.25V11C23.625 10.5168 23.2332 10.125 22.75 10.125Z" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.0625 10.125V6.1875C10.0625 5.14321 10.4773 4.14169 11.2158 3.40327C11.9542 2.66484 12.9557 2.25 14 2.25C15.0443 2.25 16.0458 2.66484 16.7842 3.40327C17.5227 4.14169 17.9375 5.14321 17.9375 6.1875V10.125" stroke="#F54949" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 18.4375C14.7249 18.4375 15.3125 17.8499 15.3125 17.125C15.3125 16.4001 14.7249 15.8125 14 15.8125C13.2751 15.8125 12.6875 16.4001 12.6875 17.125C12.6875 17.8499 13.2751 18.4375 14 18.4375Z" fill="#F54949"/>
</svg>                        </td>
                      <td><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M5.96094 22.0391C4.95469 21.0328 5.62188 18.9219 5.10781 17.6859C4.59375 16.45 2.625 15.3672 2.625 14C2.625 12.6328 4.57188 11.5938 5.10781 10.3141C5.64375 9.03438 4.95469 6.96719 5.96094 5.96094C6.96719 4.95469 9.07813 5.62188 10.3141 5.10781C11.55 4.59375 12.6328 2.625 14 2.625C15.3672 2.625 16.4063 4.57188 17.6859 5.10781C18.9656 5.64375 21.0328 4.95469 22.0391 5.96094C23.0453 6.96719 22.3781 9.07813 22.8922 10.3141C23.4063 11.55 25.375 12.6328 25.375 14C25.375 15.3672 23.4281 16.4063 22.8922 17.6859C22.3563 18.9656 23.0453 21.0328 22.0391 22.0391C21.0328 23.0453 18.9219 22.3781 17.6859 22.8922C16.45 23.4063 15.3672 25.375 14 25.375C12.6328 25.375 11.5938 23.4281 10.3141 22.8922C9.03438 22.3563 6.96719 23.0453 5.96094 22.0391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.8125 11.375L12.3922 17.5L9.1875 14.4375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg></td>
                    </tr>

                  </table>
                </div>
                <div className="transparentmobile mobileversion">
                  <div className="title_transparent">
                    <span className="head_transparent">Flexible and transparent pricing</span>
                    <div className="paraof_transparent">Pricing built for businesses of all sizes. Always know what you’ll pay.</div>
                  </div>
                  <div className="features">
                    <div className="basic">
                      Basic
                    </div>
                    <div className="basic">
                      Medium
                    </div>
                    <div className="basic">
                      Advanced
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Amount of Users
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        1
                      </div>
                      <div className="induverified">
                        3
                      </div>
                      <div className="induverified">
                        Unlimited
                      </div>
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Deep Dive
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        20 Reports
                      </div>
                      <div className="induverified">
                        50 Reports
                      </div>
                      <div className="induverified">
                        Unlimited
                      </div>
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Chameleon Carrier
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        30 Searches
                      </div>
                      <div className="induverified">
                        60 Searches
                      </div>
                      <div className="induverified">
                        Unlimited
                      </div>
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Leads Database
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        1 State
                      </div>
                      <div className="induverified">
                        3 State
                      </div>
                      <div className="induverified">
                        Unlimited
                      </div>
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Prospect Tracker
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9 8 4 8.9 4 10 L 4 20 C 4 21.1 4.9 22 6 22 L 18 22 C 19.1 22 20 21.1 20 20 L 20 10 C 20 8.9 19.1 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 12 13 C 13.1 13 14 13.9 14 15 C 14 16.1 13.1 17 12 17 C 10.9 17 10 16.1 10 15 C 10 13.9 10.9 13 12 13 z" /></svg>
                      </div>
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                          <path d="M6.46094 22.5391C5.45469 21.5328 6.12188 19.4219 5.60781 18.1859C5.09375 16.95 3.125 15.8672 3.125 14.5C3.125 13.1328 5.07188 12.0938 5.60781 10.8141C6.14375 9.53438 5.45469 7.46719 6.46094 6.46094C7.46719 5.45469 9.57813 6.12188 10.8141 5.60781C12.05 5.09375 13.1328 3.125 14.5 3.125C15.8672 3.125 16.9063 5.07188 18.1859 5.60781C19.4656 6.14375 21.5328 5.45469 22.5391 6.46094C23.5453 7.46719 22.8781 9.57813 23.3922 10.8141C23.9063 12.05 25.875 13.1328 25.875 14.5C25.875 15.8672 23.9281 16.9063 23.3922 18.1859C22.8563 19.4656 23.5453 21.5328 22.5391 22.5391C21.5328 23.5453 19.4219 22.8781 18.1859 23.3922C16.95 23.9063 15.8672 25.875 14.5 25.875C13.1328 25.875 12.0938 23.9281 10.8141 23.3922C9.53438 22.8563 7.46719 23.5453 6.46094 22.5391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.3125 11.875L12.8922 18L9.6875 14.9375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                          <path d="M6.46094 22.5391C5.45469 21.5328 6.12188 19.4219 5.60781 18.1859C5.09375 16.95 3.125 15.8672 3.125 14.5C3.125 13.1328 5.07188 12.0938 5.60781 10.8141C6.14375 9.53438 5.45469 7.46719 6.46094 6.46094C7.46719 5.45469 9.57813 6.12188 10.8141 5.60781C12.05 5.09375 13.1328 3.125 14.5 3.125C15.8672 3.125 16.9063 5.07188 18.1859 5.60781C19.4656 6.14375 21.5328 5.45469 22.5391 6.46094C23.5453 7.46719 22.8781 9.57813 23.3922 10.8141C23.9063 12.05 25.875 13.1328 25.875 14.5C25.875 15.8672 23.9281 16.9063 23.3922 18.1859C22.8563 19.4656 23.5453 21.5328 22.5391 22.5391C21.5328 23.5453 19.4219 22.8781 18.1859 23.3922C16.95 23.9063 15.8672 25.875 14.5 25.875C13.1328 25.875 12.0938 23.9281 10.8141 23.3922C9.53438 22.8563 7.46719 23.5453 6.46094 22.5391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.3125 11.875L12.8922 18L9.6875 14.9375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Insured Certificate of Insurance Portal
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9 8 4 8.9 4 10 L 4 20 C 4 21.1 4.9 22 6 22 L 18 22 C 19.1 22 20 21.1 20 20 L 20 10 C 20 8.9 19.1 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 12 13 C 13.1 13 14 13.9 14 15 C 14 16.1 13.1 17 12 17 C 10.9 17 10 16.1 10 15 C 10 13.9 10.9 13 12 13 z" /></svg>
                      </div>
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                          <path d="M6.46094 22.5391C5.45469 21.5328 6.12188 19.4219 5.60781 18.1859C5.09375 16.95 3.125 15.8672 3.125 14.5C3.125 13.1328 5.07188 12.0938 5.60781 10.8141C6.14375 9.53438 5.45469 7.46719 6.46094 6.46094C7.46719 5.45469 9.57813 6.12188 10.8141 5.60781C12.05 5.09375 13.1328 3.125 14.5 3.125C15.8672 3.125 16.9063 5.07188 18.1859 5.60781C19.4656 6.14375 21.5328 5.45469 22.5391 6.46094C23.5453 7.46719 22.8781 9.57813 23.3922 10.8141C23.9063 12.05 25.875 13.1328 25.875 14.5C25.875 15.8672 23.9281 16.9063 23.3922 18.1859C22.8563 19.4656 23.5453 21.5328 22.5391 22.5391C21.5328 23.5453 19.4219 22.8781 18.1859 23.3922C16.95 23.9063 15.8672 25.875 14.5 25.875C13.1328 25.875 12.0938 23.9281 10.8141 23.3922C9.53438 22.8563 7.46719 23.5453 6.46094 22.5391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.3125 11.875L12.8922 18L9.6875 14.9375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                          <path d="M6.46094 22.5391C5.45469 21.5328 6.12188 19.4219 5.60781 18.1859C5.09375 16.95 3.125 15.8672 3.125 14.5C3.125 13.1328 5.07188 12.0938 5.60781 10.8141C6.14375 9.53438 5.45469 7.46719 6.46094 6.46094C7.46719 5.45469 9.57813 6.12188 10.8141 5.60781C12.05 5.09375 13.1328 3.125 14.5 3.125C15.8672 3.125 16.9063 5.07188 18.1859 5.60781C19.4656 6.14375 21.5328 5.45469 22.5391 6.46094C23.5453 7.46719 22.8781 9.57813 23.3922 10.8141C23.9063 12.05 25.875 13.1328 25.875 14.5C25.875 15.8672 23.9281 16.9063 23.3922 18.1859C22.8563 19.4656 23.5453 21.5328 22.5391 22.5391C21.5328 23.5453 19.4219 22.8781 18.1859 23.3922C16.95 23.9063 15.8672 25.875 14.5 25.875C13.1328 25.875 12.0938 23.9281 10.8141 23.3922C9.53438 22.8563 7.46719 23.5453 6.46094 22.5391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.3125 11.875L12.8922 18L9.6875 14.9375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="tablecomponent">
                    <div className="featureexplain">
                      Insurance Carrier Database
                    </div>
                    <div className="verifiedboxes">
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9 8 4 8.9 4 10 L 4 20 C 4 21.1 4.9 22 6 22 L 18 22 C 19.1 22 20 21.1 20 20 L 20 10 C 20 8.9 19.1 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 12 13 C 13.1 13 14 13.9 14 15 C 14 16.1 13.1 17 12 17 C 10.9 17 10 16.1 10 15 C 10 13.9 10.9 13 12 13 z" /></svg>
                      </div>
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9 8 4 8.9 4 10 L 4 20 C 4 21.1 4.9 22 6 22 L 18 22 C 19.1 22 20 21.1 20 20 L 20 10 C 20 8.9 19.1 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 12 13 C 13.1 13 14 13.9 14 15 C 14 16.1 13.1 17 12 17 C 10.9 17 10 16.1 10 15 C 10 13.9 10.9 13 12 13 z" /></svg>

                      </div>
                      <div className="induverified">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                          <path d="M6.46094 22.5391C5.45469 21.5328 6.12188 19.4219 5.60781 18.1859C5.09375 16.95 3.125 15.8672 3.125 14.5C3.125 13.1328 5.07188 12.0938 5.60781 10.8141C6.14375 9.53438 5.45469 7.46719 6.46094 6.46094C7.46719 5.45469 9.57813 6.12188 10.8141 5.60781C12.05 5.09375 13.1328 3.125 14.5 3.125C15.8672 3.125 16.9063 5.07188 18.1859 5.60781C19.4656 6.14375 21.5328 5.45469 22.5391 6.46094C23.5453 7.46719 22.8781 9.57813 23.3922 10.8141C23.9063 12.05 25.875 13.1328 25.875 14.5C25.875 15.8672 23.9281 16.9063 23.3922 18.1859C22.8563 19.4656 23.5453 21.5328 22.5391 22.5391C21.5328 23.5453 19.4219 22.8781 18.1859 23.3922C16.95 23.9063 15.8672 25.875 14.5 25.875C13.1328 25.875 12.0938 23.9281 10.8141 23.3922C9.53438 22.8563 7.46719 23.5453 6.46094 22.5391Z" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.3125 11.875L12.8922 18L9.6875 14.9375" stroke="#0095ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </>) : (null)
            }



          </div>

        </div>
      </div>
      <div className="abovetofooter">

      </div>

    </>
  )
}

export default Getstarted