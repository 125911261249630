import React, { useState } from 'react'
import Navbar from '../component/Navbar'
import HeroSection from '../component/HeroSection'
import Carousel from '../component/Carousel'
import Emphasis from '../component/Emphasis'
import Review from '../component/Review'
import Faq from '../component/Faq'
import AboutUs from '../component/AboutUs'



import StackCamlion from './Chamelon/StackCamlion'
import StackDeep from './Chamelon/StackDeep'
import StackProspect from './Chamelon/StackProspect'
import Mobilechemeleon from './Chamelon/Mobilechemeleon'
import Prospecter from './Chamelon/Prospecter'
import Leads from './Chamelon/Leads'
import Propect from './Chamelon/Propect'
import LeadsP from './Chamelon/LeadsP'
import Carouselhome from '../component/Carouselhome'

function Home() {

  const [activeButton, setActiveButton] = useState('Monthly');

  const handleClick = (buttonType) => {
    setActiveButton(buttonType);
  };
  return (
    <div className='homehero'>
      <div className='Herosection'>
        <Navbar />
        <HeroSection />
      </div>
      <AboutUs />
      <div className='transpotation'>
        <div className='coveroftranspotation'>
          <span className='aboutus'>
            Products
          </span>
          <div className='texthead'>
            Transportation Insurance Data
          </div>
          <p className='paraoftranspo transporttextg'>
            From team challenges, we developed top-tier transportation insurance software. Access motor carrier safety data instantly, streamline quoting, track loss runs & IFTA’s, and boost your business with ease.
          </p>
        </div>
        <div className='positionstick'>
          <div className='card1'>
            <div className='fordesk'>
              <StackCamlion />

            </div>      <div className='formobile mt-2'>
              {/* <img src={mobileproject1} alt='project1'></img> */}

              <Mobilechemeleon />            </div>
          </div>
          <div className='card2'>
            <div className='fordesk'>
              <StackDeep />

            </div>      <div className='formobile mt-2'>
              <Prospecter />

            </div>
          </div>
          {/* <div className=''>
            <div className='fordesk'>
             <StackProspect/>

            </div>      <div className='formobile mt-2'>
<Propect/>
            </div>
          </div> */}
          <div className='card3'>
            <div className='fordesk'>
              <LeadsP />
            </div>
            <div className='formobile mt-2' style={{ marginBottom: "200px" }}>
              <Leads />
            </div>
          </div>
          <div className='mt-5 card5 forbottominmobile'>
            <div className='coveroftranspotation coverabove covergap'>
              <span className='aboutus'>
                Pricing
              </span>
              <div className='texthead txtmar'>
                Pricing Solutions for Every Agency       </div>
              <p className='paraoftranspo '>
                Unlock Tailored Pricing Solutions Designed to Accommodate Insurance Agencies of Every Size, Empowering Your Sales to Reach Unprecedented Heights </p>
              <div className='butncover'>
                <div className='anuallbuttons'>
                  <div
                    className={`monthly ${activeButton === 'Monthly' ? 'activebtn' : ''}`}
                    onClick={() => handleClick('Monthly')}
                  >
                    Monthly
                  </div>
                  <div
                    className={`monthly ${activeButton === 'Yearly' ? 'activebtn' : ''}`}
                    onClick={() => handleClick('Yearly')}
                  >
                    Yearly
                    <div className='savesection  savebtns '>Save 25%</div>
                    <div className='savesection2  d-none'>25%</div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
      <Carouselhome activeButton={activeButton}/>
      <div className='formobilbot2'>
        <Emphasis />
      </div>
      {/* <Review /> */}
      <Faq />
      <div className="abovetofooter">

      </div>


    </div>
  )
}

export default Home