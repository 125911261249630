import { message, Select } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const { Option } = Select;

const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", 
  "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", 
  "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", 
  "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", 
  "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", 
  "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];
const FormComponent = ({ handleform }) => {
  const[loading,setLoading]=useState(false)

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    full_name: '',
    company_name: '',
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
    company_address: '',
    company_address2: '',
    company_city: '',
    company_state: '',
    company_zip: '',
    user_status: 'active',
  });

  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // Retrieve stored form data from localStorage
  useEffect(() => {
    const storedFormData = localStorage.getItem('formData');
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      const fullName = `${parsedData.full_name} ${parsedData.last_name || ''}`.trim();
      setFormData({
        ...parsedData,
        full_name: fullName
      });
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' }); // Clear error when user starts typing
  };

  const handleStateChange = (value) => {
    setFormData({ ...formData, company_state: value });
    setErrors({ ...errors, company_state: '' });
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10); // Remove non-digits and limit to 10 digits
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})${match[2]}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, phone: formattedPhoneNumber });
    setErrors({ ...errors, phone: '' }); // Clear error when user starts typing
  };

  const handleSubmit = async (submissionData) => {
    setLoading(true)
    try {
      const response = await axios.post('https://app.freightinsdata.com/api/temp_user/create', submissionData);
      
      if (response.status === 201) {
        console.log('Data submitted successfully!');
        console.log('Response:', response.data);
        message.success('Data submitted successfully!');
        // Handle successful submission
        handleform(); // Trigger form submission handler

      } else {
        console.error('Error:', response.status);
        console.error('Response:', response.data);
        message.error('Failed to submit data.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      message.error(error.response.data.message);
    }finally{
      setLoading(false)
    }
  };

  const handleForm = async(e) => {
    e.preventDefault();
  // Split first_name into first and last name if there's a space
  let firstName = formData.first_name;
  let lastName = "";
  
  if (firstName.includes(" ")) {
    const nameParts = firstName.split(" ");
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(" ");
  }
  
  // Create `submissionData` with only the required properties
  const submissionData = {
    company_address: formData.company_address,
    company_address2: formData.company_address2,
    company_city: formData.company_city,
    company_name: formData.company_name,
    company_state: formData.company_state,
    company_zip: formData.company_zip,
    email: formData.email,
    first_name: firstName,
    last_name: lastName,
    full_name: `${firstName} ${lastName}`,
    password: formData.password,
    confirm_password: formData.confirm_password,
    phone: formData.phone,
    user_status: 'active'
  };
  
  const newErrors = {};
  let hasError = false;
  
  // Validation checks
  Object.keys(submissionData).forEach((key) => {
    if (!submissionData[key] && key !== 'company_address2' && key !== 'last_name') {
      newErrors[key] = 'This field is required';
      console.log("error here",key)
      hasError = true;
    }
  });


    if (submissionData.password !== formData.confirm_password) {
      newErrors.confirm_password = 'Passwords do not match';
      hasError = true;
      console.log("error here")

    }

    if (submissionData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      hasError = true;
      console.log("error hereemail")
    }

    if (submissionData.phone && !/^\(\d{3}\)\d{3}-\d{4}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is invalid';
      hasError = true;
      console.log("error herephone")
    }

    setErrors(newErrors);

    if (hasError) {
      message.error('Please fill all the fields correctly.');
      return;
    }
 
    // Format full name from the input
 


    localStorage.setItem('formData', JSON.stringify(submissionData)); // Save form data to localStorage
    handleSubmit(submissionData); // Submit form data
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const errorStyle = {
    borderColor: 'red',
    borderWidth: '2px'
  };
  return (
    <div className='form-wraper'>
      <form className="row g-3" onSubmit={handleForm}>
        <div className="col-md-6">
          <label htmlFor="first_name" className="form-label">Full Name</label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            placeholder="Full Name"
            value={formData.first_name}
            onChange={handleChange}
            style={errors.first_name ? errorStyle : {}}
          />

       {errors.first_name && (
  <div className="errormessage" role="alert">

    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg>
    Please enter your Complete Name
  </div>
)}

        </div>

        <div className="col-md-6">
          <label htmlFor="company_name" className="form-label">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="company_name"
            placeholder='Company Name'
            value={formData.company_name}
            onChange={handleChange}
            style={errors.company_name ? errorStyle : {}}
          />
          {errors.company_name && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Company Name </div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="phone" className="form-label">Phone Number</label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            placeholder='(XXX)XXX-XXXX'
            value={formData.phone}
            onChange={handlePhoneChange}
            style={errors.phone ? errorStyle : {}}
          />
          {errors.phone && <div className="errormessage">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg>
            Please Enter Phone Number</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="text"
            className="form-control"
            id="email"
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
            style={errors.email ? errorStyle : {}}
          />
          {errors.email && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Your Email</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="password" className="form-label">Password</label>
          <div className="input-group">
            <div className='forpasswortype'                 style={errors.password ? { ...errorStyle, border: '2px solid red', borderRadius: '10px', marginBottom: '20px' } : {}}
            >
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                style={errors.password ? {errorStyle} : {}}
              />
              <div className='mt-3 mx-3' style={{ cursor: "pointer" }} onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (<>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      {/* <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>) : (<>

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </>)}
              </div>
            </div>

          </div>
          {errors.password && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Password</div>}
        </div>
        <div className="col-md-6 labler">
          <label htmlFor="confirm_password" className="form-label labler">Confirm Password</label>
          <div className="input-group">
            <div className='forpasswortype'                 style={errors.confirm_password ? { ...errorStyle, border: '2px solid red', borderRadius: '10px', marginBottom: '20px' } : {}}
            >
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                id="confirm_password"
                placeholder='Confirm Password'
                value={formData.confirm_password}
                onChange={handleChange}
                style={errors.confirm_password ? errorStyle : {}}
              />
              <div className='mt-3 mx-3' style={{ cursor: "pointer" }} onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordVisible ? (<>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      {/* <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>) : (<>

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </>)}
              </div>

            </div>


          </div>
          {errors.confirm_password && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Must Be Same With Password</div>}
        </div>
        <div className="col-md-6 labler2">
          <label htmlFor="company_address" className="form-label">Company Address</label>
          <input
            type="text"
            className="form-control"
            id="company_address"
            placeholder='Company Address'
            value={formData.company_address}
            onChange={handleChange}
            style={errors.company_address ? errorStyle : {}}
          />
          {errors.company_address && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Company Address</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="company_address2" className="form-label">Company Address 2 (Optional)</label>
          <input
            type="text"
            className="form-control"
            id="company_address2"
            placeholder='Company Address 2'
            value={formData.company_address2}
            onChange={handleChange}
            style={errors.company_address2 ? errorStyle : {}}
          />
          {errors.company_address2 && <div className="invalid-feedback">{errors.company_address2}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="company_city" className="form-label">Company City</label>
          <input
            type="text"
            className="form-control"
            id="company_city"
            placeholder='Company City'
            value={formData.company_city}
            onChange={handleChange}
            style={errors.company_city ? errorStyle : {}}
          />
          {errors.company_city && <div className="errormessage">
            
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Company City</div>}
        </div>
        <div className="col-md-3">
          <label htmlFor="company_state" className="form-label">Company State</label>
          <Select
            id="company_state"
            placeholder="Select a state"
            value={formData.company_state}
            onChange={handleStateChange}
              style={errors.company_state ? { ...errorStyle, width: '100%',height:'55px',border:'2px solid red', borderRadius:'10px',marginBottom:'20px' } : { width: '100%',height:'55px' }}
            showSearch
            optionFilterProp="children"
          >
            {states.map(state => (
              <Option key={state} value={state}>{state}</Option>
            ))}
          </Select>
          {errors.company_state && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Company State</div>}
        </div>
        <div className="col-md-3">
          <label htmlFor="company_zip" className="form-label">Zip Code</label>
          <input
            type="text"
            className="form-control"
            id="company_zip"
            placeholder='Zip Code'
            value={formData.company_zip}
            onChange={handleChange}
            style={errors.company_zip ? errorStyle : {}}
          />
          {errors.company_zip && <div className="errormessage"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
<path fill='red' d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
</svg> Please Enter Company Zip Code</div>}
        </div>
        <div className="col-12 text-end">
          <div className='d-flex justify-content-end gap-4 formbtn'>
            <button type="button" className="btn btn-secondry"><Link to='/'>Back</Link></button>
            <button type="submit" className="btn btn-secondry">{loading?"Processing...":"Continue"}</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
