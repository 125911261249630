import React from 'react'
import standimg1 from '../images/product/standimg1.png'
import standimg2 from '../images/product/standright.png'
import standimg3 from '../images/product/Ellipse .png'
import standimg4 from '../images/product/srandright5.png'
import { Link } from 'react-router-dom'
function StanderdProduct() {
    return (
        <>
            <div className=" text-center headerpading " >
                <div className=" containerwidth3 ">
                    <p className='standerdheading text-center mx-auto sectioncolor'>10X Growth Starts Today</p>
                    <p className='standerdpera sectioncolor  mx-auto '>
                        Today's the day to transform your insurance agency with unparalleled data, enabling you to write more premium than ever before. Don't miss out on our revolutionary features at Freight Insurance Data—essential for any agency handling commercial auto insurance.                    </p>
                </div>
            </div>

            <div className="standerdmain  containerwidth3"  >
                <div className='standleft'>
                    <h2 className='standheding'>Sales<br />
                        <span className='standheding2'>Chameleon Carriers</span>
                    </h2>
                    <p className='standpera'>Access enhanced underwriting capabilities with this feature, which analyzes DOT Numbers linked to high-risk companies. It examines shared information such as company representatives' names, physical and mailing addresses, phone numbers, email addresses, and more, empowering you with comprehensive insights for better risk assessment.</p>
                    <Link to='/registration'><Link to="/registration"><button className='startedbtn getsta'>Start for free</button></Link></Link> 
                </div>
                <div className='stanright'>
                    <img src={standimg1} alt="" className='' width="100%" />
                </div>

            </div>
            <div className="standerdmain containerwidth3 reversecolum " >

                <div className='stanright'>
                    <img src={standimg1} alt="" className='' width="100%" />
                </div>
                <div className='standerleft'>
                    <h2 className='standheding'>Sales<br />
                        <span className='standheding2'>Deep drive</span>
                    </h2>
                    <p className='standpera'>Access a detailed account of any quoted DOT Number for comprehensive insights. Avoid blindsides from carriers or underwriters by accessing lead data during quoting. Gain visibility into inspections, crashes, and complete company information, empowering informed decisions throughout the process.</p>

                    <Link to='/registration'>  <button className='startedbtn getsta'>Start for free</button></Link>
                </div>

            </div>
            {/* <div className="standerdmain  containerwidth3" >
                <div className='standerleft'>
                    <h2 className='standheding'>Sales <br />
                        <span className='standheding2'>
                            Prospect Tracker
                        </span>
                    </h2>
                    <p className='standpera'>Efficiently manage commercial auto leads with unprecedented ease. Maintain detailed records and swiftly generate quotes across multiple insurance carriers from a single, user-friendly platform, streamlining your workflow and enhancing productivity.</p>

                    <Link to='/registration'>  <button className='startedbtn'>Started for free</button></Link>
                </div>
                <div className='stanright'>
                    <img src={standimg1} alt="" className='' width="100%" />
                </div>

            </div> */}
             <div className="standerdmain  containerwidth3" >
                <div className='standerleft'>
                    <h2 className='standheding'>Sales <br />
                        <span className='standheding2'>
                        Leads Database
                        </span>
                    </h2>
                    <p className='standpera'>Access an extensive and detailed list spanning all 50 states, including Mexican and Canadian carriers. Elevate your sales goals with our leads database, offering customizable filters by vehicle type, time in business, carried commodities, and more. Identify top insurance writers per state to maximize business opportunities and achieve unparalleled success.</p>

                    <Link to='/registration'>  <button className='startedbtn getsta'>Start for free</button></Link>
                </div>
                <div className='stanright'>
                    <img src={standimg1} alt="" className='' width="100%" />
                </div>

            </div>
            {/* <div className="standerdmain  containerwidth3 reversecolum" >

                <div className='stanright'>
                    <img src={standimg1} alt="" className='' width="100%" />
                </div>
                <div className='standerleft'>
                    <h2 className='standheding'>Sales <br />
                        <span className='standheding2'>Leads Database</span>
                    </h2>
                    <p className='standpera'>Access an extensive and detailed list spanning all 50 states, including Mexican and Canadian carriers. Elevate your sales goals with our leads database, offering customizable filters by vehicle type, time in business, carried commodities, and more. Identify top insurance writers per state to maximize business opportunities and achieve unparalleled success.</p>

                    <Link to='/registration'>  <button className='startedbtn'>Started for free</button></Link>
                </div>

            </div> */}
            <div className="standdmain  " style={{ overflowX: "hidden" }}>


                <div className='standerleft2'>
                    <h2 className='standheding3'>Skyrocket your Sales 🚀
                        <br />
                        <span className='standheding4'>with Freight Insurance Data</span>
                    </h2>
                    <p className='standpera2'>Freight Insurance Data offers more than just top-tier leads; it provides customizable targeting options to suit your needs. Additionally, access superior underwriting data to streamline the quoting process and avoid unnecessary back-and-forth after binding.
                        <br />
                        <p className='pt-4'>
                            Don't hesitate—invest in the comprehensive tools your agency deserves today.
                        </p>

                    </p>

                    <Link to='/registration'>  <button className='startedbtn2 forrela zoom-in' style={{border:"none"}}>Start for free</button></Link>
                </div>
                <div className='standerright2 ' >
                    <div>
                        <img src={standimg2} alt="" className='' />
                        <img src={standimg3} alt="" className='elips' />
                    </div>
                    <div>
                        <img src={standimg2} alt="" className='' />
                    </div>


                </div>
                <div className='standerright4 d-none ' >

                    <img src={standimg4} alt="" className='' width="100%" />





                </div>


            </div>


        </>
    )
}

export default StanderdProduct