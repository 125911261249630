import React, { useEffect, useState } from 'react'
import Brandlogo from "./Brandlogo.svg"
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

function Navbar() {
    const [visible, setVisible]=useState(false)
    const [isRotated, setIsRotated] = useState(false);

    const handleClick = () => {
      setVisible(!visible);
      setIsRotated(!isRotated);
    };
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [show, setShow] = useState(true);
  
    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setShow(
          (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 56) || currentScrollPos < 56
        );
        setPrevScrollPos(currentScrollPos);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [prevScrollPos, show]);
  
    return (
        <>
       
            <nav  className="navbar navbar-expand-lg " >
                <div className="navcontainer">
                    <Link className="navbar-brand" to="/">
                        <img src={Brandlogo} width="178px" height='34px' alt='brand logo'></img>
                   </Link>
                    <div className="navbar-toggler"  onClick={handleClick}>
      {visible ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={isRotated ? 'rotate' : ''}
        >
          <path
            d="M22 2L2 22"
            stroke="white"
            strokeWidth="2.08333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 22L2 2"
            stroke="white"
            strokeWidth="2.08333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          className={isRotated ? 'rotate' : ''}
        >
          <path
            d="M6.25 20H33.75"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.25 10H33.75"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.25 30H33.75"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                                <Link className="nav-link hoverlink" aria-current="page" to="/">
                                    Home
                               </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link hoverlink" aria-current="page" to="/aboutus">
                                    About us
                               </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link hoverlink" to="/Products">
                                    Products
                               </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link hoverlink" to="/Pricing">
                                    Pricing
                               </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link hoverlink" to="/faq">
                                    FAQs
                               </Link>
                            </li>
                         
                        
                        </ul>
                        <div className="d-flex align-items-center gap-4 " role="search">
                            <a href="https://app.freightinsdata.com/login"  className="linkbtn hoverlink " >
                                Log in
                           </a>
                           <Link to='/registration '>
                            <button className="getstartedbtn getsta" type="submit">
                                Get started
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
            <Drawer
      placement="top"
      open={visible}
      onClose={()=>{setVisible(false)}}
      style={{ backgroundColor: '#01101E'  }}
      height="100vh"
      closeIcon={null}
    >
     <div className='nav_items_canvas'>
     <span className='item_nav'>
   
   <Link className="nav-link " aria-current="page" to="/">
                               Home
                          </Link>
   </span>
        <span className='item_nav'>
   
        <Link className="nav-link " aria-current="page" to="/aboutus">
                                    About us
                               </Link>
        </span>
        <span className='item_nav'>
        <Link className="nav-link " aria-current="page" to="/Products">
        Products
                               </Link>
        </span>
        <span className='item_nav'>
        <Link className="nav-link " aria-current="page" to="/pricing">
        Pricing
                               </Link>
        
        </span>
        <span className='item_nav'>
        <Link className="nav-link " aria-current="page" to="/faq">
       FAQs
                               </Link>
        </span>
    
        <span className='item_line_border'>
        
        </span>
       <a href="https://app.freightinsdata.com/login" ><span className='item_nav'>
        Log in
        </span></a> 
        <Link to="/registration">
        <span className='item_button colobtn'>
        
          Get started
         
       
        </span>
        </Link>
        
     </div>
    </Drawer>
   
   

        </>
    )
}

export default Navbar