import React from 'react'
import brand from "./Images/Brandlogo.svg"
import { Link } from 'react-router-dom'
function Blogfooter() {
  return (
    <div className='Blogfooter'>
        <div className='footernav'>
          <div className='foimage'>
            <img src={brand} width="156px" alt='footer image'></img>
            </div>  
            <div className='alllinksfor_footer'>
               <Link to='/'><span>Home</span></Link> 
                <span>Lastet posts</span>
                <span>Instagram</span>
                <span>Facebook</span>
                <span>Youtube</span>
            </div>
            <div className='frieightblog'>
            Freight Blog  © 2024
            </div>
        </div>
    </div>
  )
}

export default Blogfooter